import gql from "graphql-tag";

export const GET_SPECIFIC_CHARITEERS = gql`
  query GetSpecificChariteers($GCI: GetChariteersInput!) {
    getSpecificChariteers(getChariteersInput: $GCI) {
      chariteer {
        id
        username
        nameFirst
        nameLast
        emailAddress
      }
    }
  }
`;

export const SEND_CHARITEERS_MANA_ALERT = `mutation SendChariteersManaAlert($sendChariteersAlertInput: SendChariteerAlertInput!) {
  sendChariteersManaAlert(sendChariteersAlertInput: $sendChariteersAlertInput) {
    id
  }
}
`;

<template>
  <Teleport to="#modalScreen">
    <div class="fixed top-0 left-0 w-screen h-screen z-80">
      <!-- Black Background -->
      <div
        name="Blackground"
        class="bg-black opacity-90 w-screen h-screen absolute top-0 left-0"
        @click="() => close()"
      />

      <!-- The Pop Up -->
      <div
        class="bg-white w-1/2 h-2/3 z-90 mx-auto overflow-y-auto top-20 relative rounded shadow-lg p-10"
      >
        <div class="flex flex-row-reverse">
          <button
            class="bg-gray-200 hover:bg-gray-300 rounded-full p-2 px-3 border-none"
            @click="() => close()"
          >
            ✕ Close
          </button>
        </div>

        <!-- Title -->
        <h2 class="m-0">
          {{ "Edit the “" + orientationData?.name + '" Training Record' }}
        </h2>
        <h3 class="m-0">
          <i
            >For volunteer
            {{
              orientationData?.chariteer?.nameFirst +
                " " +
                orientationData?.chariteer?.nameLast
            }}</i
          >
        </h3>

        <!-- Training Status -->
        <div class="my-10">
          <h3 class="m-0">Training Status:</h3>
          <p class="text-sm italic mt-0">
            What is the current status of this particular training? Use this
            field to set a volunteer's status and notify them via email
            automatically of the change.
          </p>
          <div class="flex items-center mb-4">
            <input
              v-model="newResolutionStatus"
              id="publishing-option-1"
              type="radio"
              name="newResolutionStatus"
              value="Interested"
              class="h-4 w-4 border-gray-300"
            />
            <label
              for="publishing-option-1"
              class="text-sm font-medium text-gray-900 ml-2 block"
            >
              Interested
            </label>
          </div>
          <div class="flex items-center mb-4">
            <input
              v-model="newResolutionStatus"
              id="publishing-option-2"
              type="radio"
              name="newResolutionStatus"
              value="Provisional"
              class="h-4 w-4 border-gray-300"
            />
            <label
              for="publishing-option-2"
              class="text-sm font-medium text-gray-900 ml-2 block"
            >
              Provisional - Volunteer will be able to sign up for quests that
              Require this training.
            </label>
          </div>
          <div class="flex items-center mb-4">
            <input
              v-model="newResolutionStatus"
              id="publishing-option-3"
              type="radio"
              name="newResolutionStatus"
              value="Complete"
              class="h-4 w-4 border-gray-300"
            />
            <label
              for="publishing-option-3"
              class="text-sm font-medium text-gray-900 ml-2 block"
            >
              Complete - Volunteer will be able to sign up for quests that
              Require this training.
            </label>
          </div>
          <div class="flex items-center mb-4">
            <input
              v-model="newResolutionStatus"
              id="publishing-option-3"
              type="radio"
              name="newResolutionStatus"
              value="Failed"
              class="h-4 w-4 border-gray-300"
            />
            <label
              for="publishing-option-3"
              class="text-sm font-medium text-gray-900 ml-2 block"
            >
              Failed
            </label>
          </div>
          <div class="flex items-center mb-4">
            <input
              v-model="newResolutionStatus"
              id="publishing-option-3"
              type="radio"
              name="newResolutionStatus"
              value="Withdrawn"
              class="h-4 w-4 border-gray-300"
            />
            <label
              for="publishing-option-3"
              class="text-sm font-medium text-gray-900 ml-2 block"
            >
              Withdrawn - Select if volunteer asks to withdraw from training.
            </label>
          </div>
        </div>

        <!-- Expiration Date -->
        <div class="mb-4">
          <div class="my-1mb-0">
            <h3>
              Expiration Date:
            </h3>
            <p class="text-sm italic mt-0">
              When does this volunteer's training expire? After this point, they
              would be required to undergo re-training.
            </p>
            <div class="flex flex-col mx-4 mt-6">
              <input
                key="starting-date"
                v-model="newStartingDate"
                class="text-center w-32 m-0 p-0"
                :class="{
                  'border-red-500': errorDate,
                  'border-2': errorDate
                }"
                type="date"
                @input="validateDateInput"
              />
              <p class="text-xs text-gray-400 mt-1 mb-0">Expiration Date</p>
            </div>
            <div class="flex flex-col mx-4 mt-6">
              <input
                key="starting-time"
                v-model="newStartingTime"
                class="text-center w-32 m-0 p-0"
                :class="{
                  'border-red-500': errorTime,
                  'border-2': errorTime
                }"
                type="time"
                @input="validateTimeInput"
              />
              <p class="text-xs text-gray-400 mt-1 mb-0">
                Expiration Time, too!
              </p>
            </div>
          </div>
          <p v-if="errorTime" class="text-xs text-red-500 mt-0">
            {{ errorTime }}
          </p>
        </div>
        <br />

        <!-- Notes go here -->
        <div>
          <h3 class="m-0 blanks whitespace-pre-wrap">Notes and Thoughts:</h3>
          <p class="text-sm italic mt-0">
            If there are any notes related to this volunteer and this training
            in particular, you can record them here. (e.g. "Scheduled a virtual
            call for next Monday" or "Will be available when she is back from
            Paris"). These are private and cannot be seen by the volunteer.
          </p>
          <Editor
            v-model="newDescription"
            editorStyle="height: 100px"
            @input="validateDescriptionInput"
          >
            <template v-slot:toolbar>
              <span class="ql-formats">
                <button v-tooltip.bottom="'Bold'" class="ql-bold"></button>
                <button v-tooltip.bottom="'Italic'" class="ql-italic"></button>
                <button
                  v-tooltip.bottom="'Underline'"
                  class="ql-underline"
                ></button>
              </span>
            </template>
          </Editor>
          <p v-if="errorDescription" class="text-xs text-red-500 mt-0">
            {{ errorDescription }}
          </p>
        </div>

        <!-- The Save Button -->
        <div
          class="fixed bottom-8 left-1/2 -translate-x-1/2 mx-auto w-2/3 grid grid-cols-1 space-x-10"
        >
          <button
            v-if="errorDescription || errorGiverName || errorTime"
            class="bg-red-400 cursor-not-allowed text-white font-bold py-4 px-4 rounded border-2 border-red-100 shadow-xl"
            disabled
          >
            Please fix the errors above.
          </button>
          <button
            v-else-if="isSubmitButtonActive"
            class="bg-green-500 hover:bg-green-400 cursor-pointer text-white font-bold py-4 px-4 rounded border-2 border-green-100 shadow-xl"
            @click="() => handleSubmitChanges(changesObject)"
          >
            Save changes to this Training record!
          </button>
          <button
            v-else
            class="bg-red-400 cursor-not-allowed text-white font-bold py-4 px-4 rounded border-2 border-red-100 shadow-xl"
            disabled
          >
            Make changes to some Training properties above.
          </button>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
import { Teleport } from "vue";
import Editor from "primevue/editor";

export default {
  props: ["orientationData", "handleSubmitChanges", "close"],
  components: {
    Teleport,
    Editor
  },
  data() {
    const parsedDescription = `<p>${(this.orientationData?.note || "")
      .replace(/<b>/g, "<strong>")
      .replace(/<\/b>/g, "</strong>")}</p>`.replace(/\n/g, "<br>");

    const formatTimeFromISO = isoString => {
      const date = new Date(isoString);
      const hours = date
        .getHours()
        .toString()
        .padStart(2, "0");
      const minutes = date
        .getMinutes()
        .toString()
        .padStart(2, "0");
      return `${hours}:${minutes}`;
    };

    const formatDateFromISO = isoString =>
      new Date(isoString).toLocaleDateString();

    const parsedStartingTime = formatTimeFromISO(this.questData?.startedAt);
    const parsedStartingDate = formatDateFromISO(this.questData?.startedAt);

    let parsedEndingTime = null;
    if (this.questData?.endedAt) {
      parsedEndingTime = formatTimeFromISO(this.questData?.endedAt);
    }

    return {
      newResolutionStatus: this.orientationData?.resolution,
      placeholderDescription: parsedDescription,
      newDescription: parsedDescription,
      placeholderStartingTime: parsedStartingTime,
      placeholderStartingDate: parsedStartingDate,
      newStartingTime: parsedStartingTime,
      newStartingDate: parsedStartingDate,
      placeholderEndingTime: parsedEndingTime,
      newEndingTime: parsedEndingTime,
      errorGiverName: "",
      errorDescription: "",
      errorTime: ""
    };
  },
  setup(props) {
    console.log("Showing Edit Orientation modal...");
    console.log(props.orientationData);
    console.log(props);
    console.log("Resolution should be " + props.orientationData?.resolution);
  },
  methods: {
    validateDescriptionInput() {
      //todo: validate the description input
      if (this.newDescription.length > 299) {
        this.errorDescription =
          "The character count for this note has been reached.";
        this.newDescription = this.newDescription.slice(0, 299);
      } else {
        this.errorDescription = "";
      }
    },
    validateTimeInput() {
      // TO DO
      // This can be like "wait you set the expiration date in the past wtf"
      if (this.newStartingTime > this.newEndingTime) {
        this.errorTime = "The starting time must be before the ending time.";
      } else {
        this.errorTime = "";
      }
    },
    validateDateInput() {
      if (this.newStartingDate > this.newStartingDate) {
        this.errorDate = "The staring date must be before the ending time.";
      } else {
        this.errorTime = "";
      }
    }
  },
  computed: {
    isSubmitButtonActive() {
      console.log(
        "this.newResolutionStatus is " +
          this.newResolutionStatus +
          " and this.orientationData?.resolution is " +
          this.orientationData?.resolution
      );

      return (
        this.newResolutionStatus !== this.orientationData?.resolution ||
        this.placeholderDescription !== this.newDescription ||
        this.placeholderStartingTime !== this.newStartingTime ||
        this.placeholderStartingDate !== this.newStartingDate
      );
    },
    changesObject() {
      console.log("Updating the Changes Object");

      let returnObj = {
        id: this.orientationData.id
      };
      const parseParagraph = str => {
        return str
          .replace(/<p><br><\/p>/g, "\n")
          .replace(/<p>/g, "")
          .replace(/<\/p>/g, "\n") // Replaces <br> tags with \n (removed the space after \n for consistency)
          .replace(/<strong>/g, "<b>") // Replaces <strong> with <b> for bolding
          .replace(/<\/strong>/g, "</b>") // Replaces </strong> with </b>
          .replace(/\n+$/, "");
      };

      const desc = parseParagraph(this.newDescription || "");

      // MUST BE REVISED TO BE EXPIRATION DATE (and time is not really important, it can be noon on EXP DATE...)
      // const parseToIso = (time, date) => {
      //   const [hours, minutes] = time.split(":");
      //   const [year, month, day] = date.split("-");
      //   return new Date(year, month - 1, day, hours, minutes).toISOString();
      // };
      // const startingTime = parseToIso(
      //   this.newStartingTime,
      //   this.orientationData.startedAt.split("T")[0]
      // );

      // Let's check if we actually changed the value before stuffing it into this
      // object down here. The "id", set above, is always there of course.
      if (this.newResolutionStatus != this.orientationData?.resolution) {
        // We're updating the Publishing Status.
        returnObj.resolution = this.newResolutionStatus;
      }
      if (this.placeholderStartingTime != this.newStartingTime) {
        returnObj.expiredAt = new Date(
          `${this.newStartingDate} ${this.newStartingTime || ""}`
        );
      }
      if (desc != this.orientationData.note) {
        returnObj.note = desc;
      }
      console.log("modal changes object: ", returnObj);
      // there's not going to be an else but it would be nice to somehow check to see if we
      // put absolutely no changes into this obj besides the id. One more final "check"
      return returnObj;
    }
  }
};
</script>

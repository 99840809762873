<template>
  <div class="text-black">
    <div v-show="false">
      {{ theSiftedTrainingArray }}
    </div>
    <template v-if="theSiftedTrainingArray.length > 0">
      <div
        v-for="template in theSiftedTrainingArray"
        :key="template.templateId"
      >
        <!-- This Loop is Training Titles -->
        <h3>{{ template.name }}</h3>
        <p>
          <i>{{ template.description }}</i>
        </p>

        <!-- Applicants -->
        <template v-if="template.orientations?.length > 0">
          <!-- One table for each Training -->
          <table class="w-full text-left border-collapse">
            <!-- Table Header -->
            <thead>
              <tr class="bg-white">
                <th class="p-2 text-sm font-semibold text-gray-700">
                  First Name
                </th>
                <th class="p-2 text-sm font-semibold text-gray-700">
                  Start Date
                </th>
                <th class="p-2 text-sm font-semibold text-gray-700">
                  Expiration Date
                </th>
                <th class="p-2 text-sm font-semibold text-gray-700">Status</th>
              </tr>
            </thead>

            <!-- Table Body -->
            <tbody>
              <tr v-for="row in template.orientations" :key="row.id">
                <td class="p-2 text-sm text-gray-600">
                  {{ row.chariteer.nameFirst + " " + row.chariteer.nameLast }}
                </td>
                <td class="p-2 text-sm text-gray-600">
                  {{ prettifyDate(row.startedAt) }}
                </td>
                <td class="p-2 text-sm text-gray-600">
                  {{ prettifyDate(row.expiredAt) }}
                </td>
                <td class="p-2 text-sm text-gray-600">{{ row.resolution }}</td>
                <td class="p-2 text-sm text-gray-600">
                  <button v-on:click="showEditOrientationWindow(row)">
                    Edit
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </template>
        <template v-else>
          There are no volunteers who have applied for the
          <span
            ><b>{{ template.name }}</b></span
          >
          orientation yet.
        </template>
      </div>
    </template>
    <template v-else>
      <div>
        <p>
          Your organization does not currently have any Trainings available for
          volunteers.<br /><br />Looking to add some required Training to your
          quests? Email us at frank@charityquest.io!
        </p>
      </div>
    </template>
  </div>

  <!-- "Edit Training" (Modal) -->
  <EditOrientationRowModal
    v-if="editOrientationRowModalIsVisible"
    :handleSubmitChanges="handleSubmitOrientationUpdate"
    :close="closeEditOrientationWindow"
    :orientationData="selectedOrientation"
  />
</template>

<script>
import { useQuery } from "@vue/apollo-composable";
import { useRoute } from "vue-router";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import gql from "graphql-tag";
import { ref, toRefs, watch } from "vue";
import { getOrientationUpdateErrorText } from "@/helpers/errorTextGenerators.js";
import EditOrientationRowModal from "./modals/EditOrientationRowModal.vue";

export default {
  name: "TrainingList",
  data() {
    return {
      selectedOrientation: null, // To hold the orientation row being edited
      editOrientationRowModalIsVisible: false
    };
  },
  components: {
    EditOrientationRowModal
  },
  props: ["selectedManagedProviderId"],
  setup(props) {
    const notifyError = text => {
      toast.error(text, {
        autoClose: false
      }); // ToastOptions
    };

    const notifySuccess = text => {
      toast.success(text, {
        autoClose: 10000
      }); // ToastOptions
    };

    const route = useRoute();

    const databaseTraining = ref(null);
    const loading = ref(null);
    const error = ref(null);
    const refetch = ref(null);
    const onResult = ref(null);
    const fetchResult = useQuery(
      gql`
        query GetSpecificOrientations($GOI: GetOrientationsInput!) {
          getSpecificOrientations(getOrientationsInput: $GOI) {
            id
            providerId
            chariteer {
              nameFirst
              nameLast
            }
            templateId
            name
            description
            startedAt
            expiredAt
            resolution
            url
            note
          }
        }
      `,
      {
        GOI: {
          specificProvider: props.selectedManagedProviderId
        }
      }
    );
    databaseTraining.value = fetchResult.result || null;
    loading.value = fetchResult.loading;
    error.value = fetchResult.error;
    refetch.value = fetchResult.refetch;
    onResult.value = fetchResult.onResult;

    watch(
      () => props,
      props => {
        refetch.value({
          GOI: { specificProvider: props.selectedManagedProviderId }
        });
      },
      { deep: true }
    );

    console.log("Refetching Training!");
    console.log(databaseTraining);
    //  console.log("Can we see the Chariteers we refetched?");
    console.log(error);

    return {
      databaseTraining,
      loading,
      error,
      refetch,
      onResult,
      notifyError,
      notifySuccess
    };
  },
  methods: {
    methodName(parameter) {
      console.log("Sweet parameter: " + parameter);
    },
    siftTrainingRows(orientationsArray) {
      const groupedTemplates = [];
      let currentTemplate = null;

      orientationsArray.forEach(orientation => {
        if (
          !currentTemplate ||
          currentTemplate.templateId !== orientation.templateId
        ) {
          // Create a new template object if the templateId changes
          currentTemplate = {
            templateId: orientation.templateId,
            name: orientation.name,
            description: orientation.description,
            orientations: []
          };
          groupedTemplates.push(currentTemplate);
        }
        // Add the current orientation to the corresponding template...
        // unless it's a Template
        if (orientation.resolution != "Template")
          currentTemplate.orientations.push(orientation);
        else console.log("Template skipped, not added to array!");
      });

      return groupedTemplates;
    },
    prettifyDate(dateString) {
      if (dateString != null) {
        var theDate = new Date(dateString);

        return theDate.toLocaleString("en-US", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric"
        });
      } else {
        return "No date set";
      }
    },
    showEditOrientationWindow(orientation) {
      console.log(
        `Updating Orientation ${orientation.id} ${orientation.chariteer?.nameFirst} in a new window.`
      );

      this.selectedOrientation = orientation;
      this.editOrientationRowModalIsVisible = true;
    },
    closeEditOrientationWindow() {
      this.editOrientationRowModalIsVisible = false;
    },
    async handleSubmitOrientationUpdate(changesObject) {
      console.log("We want to update an Orientation row!");
      console.log(changesObject);

      // Hide the Modal, it has done its job
      this.editOrientationRowModalIsVisible = false;

      // Some error checking I guess
      if (changesObject != undefined && changesObject?.id != undefined) {
        console.log("An orientation is being updated!");

        // Execution
        const result = await this.mutationUpdateOrientation(changesObject);

        if (result.errors?.length > 0) {
          const errorMessage = result.errors[0].message;
          const errorCode = result.errors[0].extensions.code;

          // GET ORIENTATION ERROR FOR TOAST
          let errorText = getOrientationUpdateErrorText(
            errorCode,
            errorMessage
          );

          this.notifyError(errorText);
        } else {
          // UI and Data Refresh
          this.refetch();

          // Toast
          this.notifySuccess("Orientation has been updated!");
        }
      } else {
        console.log("There are no changes!");
      }
    },
    async mutationUpdateOrientation(changes) {
      // Make some changes to an Orientation
      console.log("User wants to update the Orientation (" + changes.id + ")");
      console.log("The new Resolution will be " + changes.resolution);
      console.log("The new Note will be " + changes.note);
      console.log("The new Expiration Date will be " + changes.expiredAt);

      const result = await fetch(process.env.VUE_APP_DATABASE_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.playerProfile.userAuthorizationToken
        },
        body: JSON.stringify({
          query: `
              mutation UpdateOrientation ($UOI: UpdateOrientationInput!) {
                updateOrientation(updateOrientationInput: $UOI) {
                  id
                  providerId
                  chariteer {
                    nameFirst
                    nameLast
                  }
                  templateId
                  name
                  description
                  startedAt
                  expiredAt
                  resolution
                  url
                  note
                }
              }
            `,
          variables: {
            UOI: {
              id: changes.id,
              resolution: changes.resolution,
              expiredAt: changes.expiredAt,
              note: changes.note,
              supervisorId: this.$store.state.playerProfile.masterPlayerID
            }
          }
        })
      });

      const data = await result.json();
      return data;
    }
  },
  computed: {
    theSiftedTrainingArray() {
      // console.log("Sifting...");
      // console.log(this.databaseTraining);
      // console.log(this.databaseTraining.value);
      if (this.databaseTraining?.value?.getSpecificOrientations != undefined) {
        return this.siftTrainingRows(
          this.databaseTraining?.value?.getSpecificOrientations
        );
      } else {
        console.log("No trainings found for this Provider.");
        return [];
      }
    }
  },
  created() {
    console.log("Training list created.");
  }
};
</script>

<template>
  <div class="bg-cq-orange text-white text-center">
    <MasterLayout>
      <FDFullWidthBanner />
      <!-- How about a HomeChariteer component and a HomeProvider component and then just show one by default -->

      <div
        class="py-10"
        v-if="
          $store.state.playerProfile.accountType == 'Chariteer' &&
            this.$store.state.playerProfile.chariteerExistsInDatabase == true
        "
      >
        <h1>Welcome to Charity Quest!</h1>

        <!-- Show a different message to Charity Staff -->
        <div v-if="$store.state.managedProviderGroupMemberships.length > 1">
          <h2>
            You belong to
            {{ $store.state.managedProviderGroupMemberships.length }}
            Organization(s) on Charity Quest.
          </h2>
          <h2>Click on a button below to see the admin console.</h2>

          <ManagedCharityCard
            v-for="charity in $store.state.managedProviderGroupMemberships"
            v-bind:key="charity.ManagedProviderId"
            v-bind:charity="charity"
          />

          <br />
          <br />
          <h3 class="mx-20">
            You can also view the other charities using this platform!
          </h3>
          <h3 class="mx-20">
            Volunteering at other charities is a great way to forge community
            connections and get new ideas.
          </h3>
          <br />
          <router-link
            class="bg-white hover:bg-gray-100 text-cq-orange font-semibold py-2 px-4 w-full border border-gray-400 rounded shadow"
            :to="{ name: 'CharityList' }"
          >
            Click here to view all the charities using this network.
          </router-link>
        </div>
        <div
          v-else-if="$store.state.managedProviderGroupMemberships.length == 1"
        >
          <h2>
            You belong to
            {{
              $store.state.managedProviderGroupMemberships[0]
                .ManagedProviderName
            }}
            on Charity Quest.
          </h2>
          <h2>Click the button below to see the admin console.</h2>

          <ManagedCharityCard
            v-for="charity in $store.state.managedProviderGroupMemberships"
            v-bind:key="charity.ManagedProviderId"
            v-bind:charity="charity"
          />

          <br />
          <br />
          <h3 class="mx-20">
            You can also view the other charities using this platform!
          </h3>
          <h3 class="mx-20">
            Volunteering at other charities is a great way to forge community
            connections and get new ideas.
          </h3>
          <br />
          <router-link
            class="bg-white hover:bg-gray-100 text-cq-orange font-semibold py-2 px-4 w-full border border-gray-400 rounded shadow"
            :to="{ name: 'CharityList' }"
          >
            Click here to view all the charities using this network.
          </router-link>
        </div>
        <!-- Just a volunteer -->
        <div v-else>
          <h3 class="mx-10">
            This website will let you choose a charity from the available
            options and sign up to volunteer if there's a spot available.
          </h3>
          <h3 class="mx-20">
            You can also remove yourself from an event if you can no longer
            attend.
          </h3>
          <br />
          <router-link
            class="bg-white hover:bg-gray-100 text-cq-orange font-semibold py-2 px-4 w-full border border-gray-400 rounded shadow"
            :to="{ name: 'CharityList' }"
          >
            Click here to view the charities using this network.
          </router-link>
          <br />
          <br />
          <br />
          <p>
            For the best Charity Quest experience, please download
            <a href="http://koji.to/@CharityQuest">our free mobile app.</a>
          </p>
        </div>
      </div>
      <div
        class="py-10"
        v-else-if="
          $store.state.playerProfile.accountType == 'Chariteer' &&
            this.$store.state.playerProfile.chariteerExistsInDatabase == false
        "
      >
        <h1>
          Before proceeding to the next screen, please verify that your account
          information below is correct.
        </h1>

        <p>Username: {{ this.$store.state.playerProfile.username }}</p>
        <p>Email Address: {{ this.$store.state.playerProfile.emailAddress }}</p>
        <p>First Name: {{ this.$store.state.playerProfile.firstName }}</p>
        <p>Last Name: {{ this.$store.state.playerProfile.lastName }}</p>
        <p>Mobile Number: {{ this.$store.state.playerProfile.phoneNumber }}</p>
        <p>Date of Birth: {{ this.$store.state.playerProfile.dateOfBirth }}</p>
        <p>Current Age: {{ this.$store.state.playerProfile.age }}</p>
        <p class="text-red-500">
          <b
            >If you see an error, please send me an email at
            <u>frank@charityquest.io</u> and I can correct it!</b
          >
        </p>

        <button
          class="bg-green-500 hover:bg-green-400 text-white font-bold py-4 px-4 rounded border border-green-100 hover:border-transparent"
          v-on:click="createChariteerAccountOnDatabase"
        >
          Click here to proceed to the Charity Select screen.
        </button>
      </div>

      <template
        v-if="$store.state.playerProfile.accountType == 'Provider'"
        class="providerHome"
      >
        <ProviderSchedule />
        <!-- Potential "Show More" Button goes here if you want to cut it off at the first 5 entries or something -->
      </template>
    </MasterLayout>
  </div>
</template>

<script>
// Home is a flexible screen that displays different components based on the player's Account Type.
// You go straight here after manual login OR auto-login
import MasterLayout from "@/components/MasterLayout.vue";
import FDFullWidthBanner from "../components/advertisements/FDFullWidthBanner.vue";
import ProviderSchedule from "@/components/ProviderSchedule.vue";
import ManagedCharityCard from "@/components/ManagedCharityCard.vue";

export default {
  name: "Home",
  components: {
    MasterLayout,
    FDFullWidthBanner,
    ProviderSchedule,
    ManagedCharityCard
  },
  methods: {
    async createChariteerAccountOnDatabase() {
      // Execution
      const result = await this.mutationCreateChariteerAccount();

      if (result.errors?.length > 0) {
        const errorMessage = result.errors[0].extensions.response.message;
        const errorCode = result.errors[0].extensions.response.statusCode;
      } else {
        this.$store.state.playerProfile.chariteerExistsInDatabase = true;
      }
    },
    async mutationCreateChariteerAccount() {
      const result = await fetch(process.env.VUE_APP_DATABASE_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.playerProfile.userAuthorizationToken
        },
        body: JSON.stringify({
          query: `
              mutation CreateChariteer ($CCI: CreateChariteerInput!) {
                createChariteer(createChariteerInput: $CCI) {
                  id,
                  username,
                  emailAddress,
                  phoneNumber,
                  dateOfBirth,
                  playerAccountType,
                  nameFirst,
                  nameLast
                }
              }
            `,
          variables: {
            CCI: {
              id: this.$store.state.playerProfile.masterPlayerID,
              username: this.$store.state.playerProfile.username,
              emailAddress: "" + this.$store.state.playerProfile.emailAddress,
              phoneNumber: this.$store.state.playerProfile.phoneNumber,
              dateOfBirth: this.$store.state.playerProfile.dateOfBirth,
              playerAccountType: "Chariteer",
              nameFirst: this.$store.state.playerProfile.firstName,
              nameLast: this.$store.state.playerProfile.lastName
            }
          }
        })
      });

      const data = await result.json();
      return data;
    }
  },
  async created() {
    // Get rid of this maintenance BS
    try {
      const websiteVersion = [0, 1, 6];

      var getTitleDataRequest = {
        Keys: "MaintenanceStatus"
      };

      const result = await window.PlayFabClientSDK.GetTitleData(
        getTitleDataRequest
      );

      // DID WE GET MAINTENANCE STATUS?
      if (result.data.Data.MaintenanceStatus != undefined) {
        // console.log("OK, so MaintenanceStatus in Data is not undefined. That's good...")
        // console.log("Maintenance Status is " + result.data.Data.MaintenanceStatus)

        var maintenanceStatusAsJSON = JSON.parse(
          result.data.Data.MaintenanceStatus
        );
        // console.log("The mandatory website version is: " + maintenanceStatusAsJSON.versionWebsite)
        // console.log("Our local version is " + websiteVersion.join("."))

        // Parse the website version of Maintenance Status
        var mandatoryVersion = maintenanceStatusAsJSON.versionWebsite.split(
          "."
        );

        // Compare it to the Internet version of Maintenance Status
        var weNeedToUpdateBecauseWeAreUsingAnOldVersion = false;

        if (mandatoryVersion[0] > websiteVersion[0]) {
          // console.log("We're an entire product version behind! Mandatory product version is " + mandatoryVersion[0] + ", we have " + websiteVersion[0] + ". Update now.")
          weNeedToUpdateBecauseWeAreUsingAnOldVersion = true;
        } else if (mandatoryVersion[1] > websiteVersion[1]) {
          // console.log("We're a version release behind! Mandatory release version is " + mandatoryVersion[1] + ", we have " + websiteVersion[1] + ". Update now.")
          weNeedToUpdateBecauseWeAreUsingAnOldVersion = true;
        } else if (mandatoryVersion[2] > websiteVersion[2]) {
          // console.log("We're a patch version behind. Mandatory patch version is " + mandatoryVersion[2] + ", we have " + websiteVersion[2] + ". Update now.")
          weNeedToUpdateBecauseWeAreUsingAnOldVersion = true;
        } else {
          // console.log("I think we're using the same version. Internet version " + maintenanceStatusAsJSON.versionWebsite + " matches the local version " + websiteVersion.join("."))
        }

        // If necessary, force an update or reload or something
        if (weNeedToUpdateBecauseWeAreUsingAnOldVersion) {
          // console.log("Forcing an update NOW...");
          // Perhaps a modal that covers the entire screen and makes you go back to the home page and reload? IDK
          // We can't make anyone do anything. I don't even know if reload() will work. But we can prevent people from using the website if they are on an old version, at the very least.
          // So it would be a warning, it would block access, my support email would be there, and that's the idea.
          // All of this would actually happen in the home page though!

          this.$router.push({ path: "/", query: { forceReload: true } });

          // console.log("Reload?")
        }
      } else {
        // console.log("No Maintenance Status detected.")
      }
    } catch (error) {
      // console.log(error)
    }
  }
};
</script>

<style scoped></style>

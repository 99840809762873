<template>
  <Teleport to="#modalScreen">
    <div class="fixed top-0 left-0 w-screen h-screen z-80">
      <!-- Black Background -->
      <div
        name="Blackground"
        class="bg-black opacity-90 w-screen h-screen absolute top-0 left-0"
        @click="() => close()"
      />

      <!-- The Pop Up -->
      <div
        class="bg-white w-1/2 h-2/3 z-90 mx-auto overflow-y-auto top-20 relative rounded shadow-lg p-10 box-border flex flex-col"
      >
        <div class="flex flex-row-reverse">
          <button
            class="bg-gray-200 hover:bg-gray-300 rounded-full p-2 px-3 border-none"
            @click="() => close()"
          >
            ✕ Close
          </button>
          <br />
        </div>

        <!-- Quest Title -->
        <h2 class="m-0">
          {{ "Send a Volunteer Alert about “" + questData.eventTitle + '"' }}
        </h2>
        <!-- Quest Time -->
        <div v-show="false" class="flex flex-col align-left mb-4">
          <div class="flex flex-row align-left my-1mb-0">
            <h3 class="self-start justify-self-end">
              Edit Quest Time:
            </h3>
            <div class="flex flex-col mx-4 mt-6">
              <input
                key="starting-time"
                v-model="newStartingTime"
                class="text-center w-32 m-0 p-0"
                :class="{
                  'border-red-500': errorTime,
                  'border-2': errorTime
                }"
                type="time"
                @input="validateTimeInput"
              />
              <p class="text-xs text-gray-400 mt-1 mb-0">Starting Time</p>
            </div>
            <p v-if="!isSupplyFeast" class="mt-6">-</p>
            <div
              v-if="!isSupplyFeast"
              class="flex flex-col align-left mt-6 mx-4"
            >
              <input
                key="ending-time"
                v-model="newEndingTime"
                class="text-center w-32 m-0 p-0"
                :class="{
                  'border-red-500': errorTime,
                  'border-2': errorTime
                }"
                type="time"
                @input="validateTimeInput"
              />
              <p class="text-xs text-gray-400 mt-1 mb-0">Ending Time</p>
            </div>
          </div>
          <p v-if="errorTime" class="text-xs text-red-500 mt-0">
            {{ errorTime }}
          </p>
        </div>

        <!-- Giver Name -->
        <div v-show="false">
          <div class="flex flex-row m-0 p-0">
            <h3 class="m-0">Supervisor's Name:</h3>
            <input
              v-model="newGiverName"
              :class="{
                'border-red-500': errorGiverName,
                'border-2': errorGiverName
              }"
              class="text-center w-32 m-0 ml-3 p-0"
              type="text"
              :placeholder="placeholderGiverName"
              @input="validateGiverNameInput"
            />
            <p v-if="errorGiverName" class="text-xs text-red-500 ml-1">
              {{ errorGiverName }}
            </p>
          </div>

          <p class="text-sm italic mt-0">
            Charity Quest will tell volunteers to report to this person, so even
            just a first name is fine. Consider including titles and last names
            too, like "Dr. Lana Jackson" or "Sr. Maria".
          </p>
        </div>

        <!-- Quest Details-->
        <div v-show="false">
          <h3 class="m-0 blanks whitespace-pre-wrap">Details:</h3>
          <Editor
            v-model="newDescription"
            editorStyle="height: 200px"
            @input="validateDescriptionInput"
          >
            <template v-slot:toolbar>
              <span class="ql-formats">
                <button v-tooltip.bottom="'Bold'" class="ql-bold"></button>
                <button v-tooltip.bottom="'Italic'" class="ql-italic"></button>
                <button
                  v-tooltip.bottom="'Underline'"
                  class="ql-underline"
                ></button>
              </span>
            </template>
          </Editor>
          <p v-if="errorDescription" class="text-xs text-red-500 mt-0">
            {{ errorDescription }}
          </p>

          <p class="text-sm italic mt-0">
            A brief description of the volunteer opportunity. This is a great
            place to include any special instructions, like "bring a water
            bottle" or "wear closed-toe shoes".
          </p>
        </div>
        <!-- Publishing Status -->
        <div v-show="false" class="my-10">
          <h3 class="m-0">Visibility Status: {{ this.newPublishingStatus }}</h3>
          <p class="text-sm italic mt-0">
            Who can see this Quest right now? Publishing Status determines which
            users can see this Quest when they use the mobile app or website. We
            recommend keeping everything as a Draft while your team is reviewing
            the schedule, and releasing Quests to the public in monthly
            intervals.
          </p>
          <div class="flex items-center mb-4">
            <input
              v-model="newPublishingStatus"
              id="publishing-option-1"
              type="radio"
              name="newPublishingStatus"
              value="Draft"
              class="h-4 w-4 border-gray-300"
            />
            <label
              for="publishing-option-1"
              class="text-sm font-medium text-gray-900 ml-2 block"
            >
              Draft - This Quest will only be visible to the managers of your
              charity.
            </label>
          </div>
          <div class="flex items-center mb-4">
            <input
              v-model="newPublishingStatus"
              id="publishing-option-2"
              type="radio"
              name="newPublishingStatus"
              value="Published"
              class="h-4 w-4 border-gray-300"
            />
            <label
              for="publishing-option-2"
              class="text-sm font-medium text-gray-900 ml-2 block"
            >
              Published - This Quest will be visible to every volunteer on
              Charity Quest.
            </label>
          </div>
          <div class="flex items-center mb-4">
            <input
              v-model="newPublishingStatus"
              id="publishing-option-3"
              type="radio"
              name="newPublishingStatus"
              value="Unlisted"
              class="h-4 w-4 border-gray-300"
            />
            <label
              for="publishing-option-3"
              class="text-sm font-medium text-gray-900 ml-2 block"
            >
              Unlisted - This Quest will only be accessible via a direct link,
              it will not appear on the map.
            </label>
          </div>
          <!-- Don't do Cancelled Yet until you have a popup that people can use -->
          <!-- Private isn't really a thing -->
        </div>
        <!-- Coming Soon -->
        <div class="my-4">
          <div class="flex gap-2 border-0 border-b border-solid">
            <!-- <input type="checkbox" id="select-all" v-model="allSelected" /> -->
            <span
              ><b>{{ chariteerList.length }}</b> volunteers</span
            >
          </div>
          <div class="flex flex-col">
            <div
              v-for="chariteer in chariteerList"
              :key="chariteer.chariteer.id"
              class="flex items-center gap-2 border-0 border-b border-solid p-1"
            >
              <input
                type="checkbox"
                :id="chariteer.chariteer.id"
                :value="true"
                checked
                disabled
              />
              <label
                :for="chariteer.chariteer.id"
                class="flex grow items-center gap-2"
              >
                <span
                  class="w-[30%] max-w-[200px] whitespace-nowrap text-ellipsis"
                  >{{
                    `${chariteer.chariteer.nameFirst} ${chariteer.chariteer.nameLast}`
                  }}</span
                >
                <span
                  class="w-[30%] max-w-[200px] whitespace-nowrap text-ellipsis"
                  >{{ chariteer.chariteer.username }}</span
                >
                <span
                  class="w-[30%] max-w-[200px] whitespace-nowrap text-ellipsis"
                  >{{ chariteer.chariteer.emailAddress }}</span
                >
              </label>
            </div>
          </div>
        </div>
        <div class="flex justify-center">
          <AlertDialogRoot>
            <AlertDialogTrigger
              class="bg-white text-grass11 font-semibold hover:bg-white/90 shadow-sm inline-flex h-[35px] items-center justify-center rounded-[4px] px-[15px] leading-none outline-none transition-all"
            >
              Send
            </AlertDialogTrigger>
            <AlertDialogPortal>
              <AlertDialogOverlay
                class="bg-blackA9 data-[state=open]:animate-overlayShow fixed inset-0 z-30"
              />
              <AlertDialogContent
                class="flex flex-col z-[100] text-[15px] data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[500px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none"
              >
                <AlertDialogTitle
                  class="text-mauve12 m-0 text-[17px] font-semibold"
                >
                  Are you sure?
                </AlertDialogTitle>
                <AlertDialogDescription
                  class="text-mauve11 mt-4 mb-5 text-[15px] leading-normal"
                >
                  You are going to send emails to
                  {{ chariteerList.length }} volunteers.
                </AlertDialogDescription>
                <div class="flex justify-end gap-2">
                  <AlertDialogCancel
                    class="text-mauve11 bg-mauve4 hover:bg-mauve5 focus:ring-0 focus:outline-none focus:shadow-none inline-flex h-[35px] items-center justify-center rounded-[4px] px-[15px] ring-0 font-semibold leading-none outline-none"
                  >
                    Cancel
                  </AlertDialogCancel>
                  <AlertDialogAction
                    class="text-red11 bg-red4 hover:bg-red5 focus:shadow-red7 inline-flex h-[35px] items-center justify-center rounded-[4px] px-[15px] font-semibold ring-0 leading-none outline-none"
                    @click="sendBulkEmailAlert"
                  >
                    Yes
                  </AlertDialogAction>
                </div>
              </AlertDialogContent>
            </AlertDialogPortal>
          </AlertDialogRoot>
        </div>

        <!-- More... -->

        <!-- The Save Button -->
        <div
          class="fixed bottom-8 left-1/2 -translate-x-1/2 mx-auto w-2/3 grid grid-cols-1 space-x-10 box-border"
        >
          <button
            v-if="errorDescription || errorGiverName || errorTime"
            class="bg-red-400 cursor-not-allowed text-white font-bold py-4 px-4 rounded border-2 border-red-100 shadow-xl"
            disabled
          >
            Please fix the errors above.
          </button>
          <button
            v-else-if="isSubmitButtonActive"
            class="bg-green-500 hover:bg-green-400 cursor-pointer text-white font-bold py-4 px-4 rounded border-2 border-green-100 shadow-xl"
            @click="() => handleSubmitChanges(changesObject)"
          >
            Send an alert to volunteers right now!
          </button>
          <button
            v-else
            class="bg-red-400 cursor-not-allowed text-white font-bold py-4 px-4 rounded border-2 border-red-100 shadow-xl"
            disabled
          >
            Make changes to some Alert properties above.
          </button>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
import { Teleport } from "vue";
import Editor from "primevue/editor";
import {
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogRoot,
  AlertDialogTitle,
  AlertDialogTrigger
} from "radix-vue";
import { SEND_CHARITEERS_MANA_ALERT } from "../../gql/chariteer";

export default {
  props: ["questData", "handleSubmitChanges", "close", "chariteers"],
  components: {
    Teleport,
    Editor,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogOverlay,
    AlertDialogPortal,
    AlertDialogRoot,
    AlertDialogTitle,
    AlertDialogTrigger
  },
  data() {
    const parsedDescription = `<p>${this.questData?.description
      .replace(/<b>/g, "<strong>")
      .replace(/<\/b>/g, "</strong>")}</p>`.replace(/\n/g, "<br>");

    const formatTimeFromISO = isoString => {
      const date = new Date(isoString);
      const hours = date
        .getHours()
        .toString()
        .padStart(2, "0");
      const minutes = date
        .getMinutes()
        .toString()
        .padStart(2, "0");
      return `${hours}:${minutes}`;
    };

    const parsedStartingTime = formatTimeFromISO(this.questData?.startedAt);

    let parsedEndingTime = null;
    if (this.questData?.endedAt) {
      parsedEndingTime = formatTimeFromISO(this.questData?.endedAt);
    }

    return {
      newGiverName: this.questData?.giverName,
      placeholderGiverName: this.questData?.giverName,
      newPublishingStatus: this.questData?.publishingStatus,
      newVolunteerMaximum: this.questData?.resourceMaxNumber,
      placeholderDescription: parsedDescription,
      newDescription: parsedDescription,
      placeholderStartingTime: parsedStartingTime,
      newStartingTime: parsedStartingTime,
      placeholderEndingTime: parsedEndingTime,
      newEndingTime: parsedEndingTime,
      errorGiverName: "",
      errorDescription: "",
      errorTime: "",
      chariteerList: this.chariteers.getSpecificChariteers
        ? this.chariteers.getSpecificChariteers.filter(
            ({ chariteer }) => !chariteer.emailAddress.includes("_DELETED")
          )
        : [],
      selectedChariteerIds: []
    };
  },
  setup(props) {
    console.log("Showing Edit Quest modal...");
    console.log(props.questData);
    // console.log("Showing Edit Quest modal...");
    // console.log(props);
    // console.log(props.close);
  },
  methods: {
    validateGiverNameInput() {
      //todo: validate the giver name input
      // s'good! Maybe limit this to 35ish. I mean we really don't need full names
      if (this.newGiverName.length > 35) {
        this.errorGiverName = "The supervisor's name is too long.";
        this.newGiverName = this.newGiverName.slice(0, 35);
      } else {
        this.errorGiverName = "";
      }
    },
    validateVolunteerMaxInput() {
      //todo: validate the volunteer max input
      // console.log("don't try it, kid...");

      // Ensure the max stays within the range of 1 to 75
      if (this.newVolunteerMaximum < 1) {
        this.newVolunteerMaximum = 1;
      } else if (this.newVolunteerMaximum > 75) {
        this.newVolunteerMaximum = 75;
      } else {
        this.errorVolunteerMax = "";
      }
    },
    validateDescriptionInput() {
      //todo: validate the description input
      if (this.newDescription.length > 10000) {
        this.errorDescription = "The description is too long.";
        this.newDescription = this.newDescription.slice(0, 10000);
      } else {
        this.errorDescription = "";
      }
    },
    validateTimeInput() {
      if (this.newStartingTime > this.newEndingTime) {
        this.errorTime = "The starting time must be before the ending time.";
      } else {
        this.errorTime = "";
      }
    },
    async sendBulkEmailAlert() {
      const result = await fetch(process.env.VUE_APP_DATABASE_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.playerProfile.userAuthorizationToken
        },
        body: JSON.stringify({
          query: SEND_CHARITEERS_MANA_ALERT,
          variables: {
            sendChariteersAlertInput: {
              senderId: this.$store.state.playerProfile.masterPlayerID,
              manaCost: 0,
              providerId: this.questData.providerId,
              questId: this.questData.id
            }
          }
        })
      });

      // console.log(result);
      const data = await result.json();
      // console.log(data); //data can have data.errors

      return data;
    }
  },
  computed: {
    isSubmitButtonActive() {
      return (
        this.newGiverName !== this.questData?.giverName ||
        this.newPublishingStatus !== this.questData?.publishingStatus ||
        this.newVolunteerMaximum !== this.questData?.resourceMaxNumber ||
        this.placeholderDescription !== this.newDescription ||
        this.placeholderStartingTime !== this.newStartingTime ||
        this.placeholderEndingTime !== this.newEndingTime
      );
    },
    isSupplyFeast() {
      return this.questData?.eventType == "SUPPLY-REQUEST";
    },
    changesObject() {
      // console.log("Updating the Changes Object");

      let returnObj = {
        id: this.questData.id
      };
      const parseParagraph = str => {
        return str
          .replace(/<p><br><\/p>/g, "\n")
          .replace(/<p>/g, "")
          .replace(/<\/p>/g, "\n") // Replaces <br> tags with \n (removed the space after \n for consistency)
          .replace(/<strong>/g, "<b>") // Replaces <strong> with <b> for bolding
          .replace(/<\/strong>/g, "</b>") // Replaces </strong> with </b>
          .replace(/\n+$/, "");
      };

      const desc = parseParagraph(this.newDescription);
      const parseToIso = (time, date) => {
        const [hours, minutes] = time.split(":");
        const [year, month, day] = date.split("-");
        return new Date(year, month - 1, day, hours, minutes).toISOString();
      };
      const startingTime = parseToIso(
        this.newStartingTime,
        this.questData.startedAt.split("T")[0]
      );

      let endingTime = null;
      if (this.newEndingTime) {
        console.log("Ending Time: ", this.newEndingTime);
        endingTime = parseToIso(
          this.newEndingTime,
          this.questData.endedAt.split("T")[0]
        );
      }

      // Let's check if we actually changed the value before stuffing it into this
      // object down here. The "id", set above, is always there of course.
      if (this.newGiverName != this.questData?.giverName) {
        // We're updating the Giver Name.
        returnObj.giverName = this.newGiverName;
      }
      if (this.newPublishingStatus != this.questData?.publishingStatus) {
        // We're updating the Publishing Status.
        returnObj.publishingStatus = this.newPublishingStatus;
      }
      if (this.newVolunteerMaximum != this.questData.resourceMaxNumber) {
        // We're updating the maximum.
        returnObj.resourceMaxNumber = this.newVolunteerMaximum;
      }
      if (this.placeholderStartingTime != this.newStartingTime) {
        returnObj.startedAt = startingTime;
      }
      if (this.placeholderEndingTime != this.newEndingTime) {
        returnObj.endedAt = endingTime;
      }
      if (desc != this.questData.description) {
        returnObj.description = desc;
      }
      console.log("modal changes object: ", returnObj);
      // there's not going to be an else but it would be nice to somehow check to see if we
      // put absolutely no changes into this obj besides the id. One more final "check"
      return returnObj;
    },
    allSelected: {
      get() {
        return this.selectedChariteerIds.length === this.chariteerList.length;
      },
      set(value) {
        if (value) {
          this.selectedChariteerIds = this.chariteerList.map(
            item => item.chariteer.id
          );
        } else {
          this.selectedChariteerIds = [];
        }
      }
    }
  }
};
</script>

<template>
  <div class="footer">
    <ul>
      <!-- Privacy Policy -->
      <li>
        <router-link
          class="top-nav-route"
          :to="{ name: 'Privacy Policy' }"
        >
          Privacy Policy
        </router-link>
      </li>

      <!-- Terms of Service -->
      <li>
        <router-link
          class="top-nav-route"
          :to="{ name: 'Terms of Service' }"
        >
          Terms of Service
        </router-link>
      </li>

      <!-- Account Removal -->
      <li>
        <router-link
          class="top-nav-route"
          :to="{ name: 'Account Removal' }"
        >
          Account Removal
        </router-link>
      </li>

      <!-- Subscribing -->
      <li>
        <router-link
          class="top-nav-route"
          :to="{ name: 'Subscribe' }"
        >
          Support Us
        </router-link>
      </li>
    </ul>

    <!-- Version Number -->
    <br>
    <p class="text-center text-sm font-light">Website Version 0.1.119</p>
    <br>
    <p class="text-center font-light">© 2025 Game Revenant. Charities are property of their respective owners. Game Revenant Ltd. Headquarters are in Hoboken, New Jersey.</p>

  </div>
</template>

<script>

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.footer {
  color: white;
  font-weight: 600;
  background-color: #2d2d2d;
  margin: 0px;
  padding-top: 2.5vh;
  padding-right: 0px;
  padding-left: 0px;
  padding-bottom: 5vh;
  width: 100%;
  height: 5vh;
}

p {
  margin: 0px;
  padding-right: 20px;
  padding-left: 20px;
}

a {
  color: white;
}

a:hover {
  color: #FFA94E;
}

ul {
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
  padding: 0px;
}

li {
  list-style: none;
  padding-right: 15px;
  padding-left: 15px;
}

small {
  margin: 0px;
  font-weight: 200;
}

</style>

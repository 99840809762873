<template>
  <div class="chariteersBox">
    <div class="chariteers">
      <!-- Column and Row Text
      <h4>{{ columnDisplayText }}</h4>
      <br>
       -->

      <!-- Preset Loadouts -->
      <div class="preset-loadouts-flexbox" v-show="false">
        <!-- Contact -->
        <button
          class="column-filter-button"
          v-bind:class="{ columnSelected: columnDisplayName == 'Contact Info' }"
          v-on:click="filterByPreset('Contact Info')"
        >
          <img
            class="column-filter-button-image"
            alt="Letter"
            src="../assets/CQ-Butticon_Letter_Grey.png"
            v-show="columnDisplayName != 'Contact Info'"
          />
          <img
            class="column-filter-button-image"
            alt="Letter"
            src="../assets/CQ-Butticon_Letter.png"
            v-show="columnDisplayName == 'Contact Info'"
          />
          <p>CONTACT</p>
        </button>

        <!-- Goals -->
        <button
          class="column-filter-button"
          v-bind:class="{ columnSelected: columnDisplayName == 'Goals' }"
          v-on:click="filterByPreset('Goals')"
        >
          <img
            class="column-filter-button-image"
            alt="Goblet"
            src="../assets/CQ-Butticon_Goblet_Grey.png"
            v-show="columnDisplayName != 'Goals'"
          />
          <img
            class="column-filter-button-image"
            alt="Goblet"
            src="../assets/CQ-Butticon_Goblet.png"
            v-show="columnDisplayName == 'Goals'"
          />
          <p>GOALS</p>
        </button>
      </div>

      <!-- Number of Chariteers -->
      <div class="">
        <!-- Preset Views -->
        <ul
          class="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 list-none gap-x-1"
        >
          <!-- Leaderboard -->
          <li class="me-2 max-w-lg h-30">
            <!-- Active Leaderboard Button -->
            <div v-if="currentlySelectedPreset == 'Leaderboard'">
              <button
                v-on:click="() => (currentlySelectedPreset = 'Leaderboard')"
                class="inline-block bg-cq-orange text-white p-4 font-bold rounded-sm active cursor-pointer"
              >
                <img
                  class="w-16 h-16 mr-2"
                  alt="A little badge with a star on it"
                  src="../assets/navicons/CQ-Navicon_Award_White.png"
                />
                <br />
                Leaderboard
              </button>
            </div>
            <!-- Inactive Leaderboard Button -->
            <div v-else>
              <button
                v-on:click="() => (currentlySelectedPreset = 'Leaderboard')"
                class="inline-block bg-gray-100 text-gray-500 p-4 font-bold rounded-sm active cursor-pointer"
              >
                <img
                  class="w-16 h-16 mr-2"
                  alt="A little badge with a star on it"
                  src="../assets/navicons/CQ-Navicon_Award_Gray.png"
                />
                <br />
                Leaderboard
              </button>
            </div>
          </li>
          <!-- Training -->
          <li class="me-2 max-w-lg h-30">
            <!-- Active Training Button -->
            <div v-if="currentlySelectedPreset == 'Training'">
              <button
                v-on:click="() => (currentlySelectedPreset = 'Training')"
                class="inline-block bg-cq-orange text-white p-4 font-bold rounded-sm active cursor-pointer"
              >
                <img
                  class="w-16 h-16 mr-2"
                  alt="A little badge with a star on it"
                  src="../assets/navicons/CQ-Navicon_Book_White.png"
                />
                <br />
                Orientations
              </button>
            </div>
            <!-- Inactive Training Button -->
            <div v-else>
              <button
                v-on:click="() => (currentlySelectedPreset = 'Training')"
                class="inline-block bg-gray-100 text-gray-500 p-4 font-bold rounded-sm active cursor-pointer"
              >
                <img
                  class="w-16 h-16 mr-2"
                  alt="A little badge with a star on it"
                  src="../assets/navicons/CQ-Navicon_Book_Gray.png"
                />
                <br />
                Orientations
              </button>
            </div>
          </li>
        </ul>

        <br />

        <!-- Helpful row of buttons
        <div class="ml-auto self-end">
          <button v-show=false class="bg-gray-700 font-bold text-white cursor-pointer">Download Excel File</button>
          <button class="bg-gray-700 font-bold text-white cursor-pointer" v-on:click="downloadChariteerDataAsCSVFile">Download CSV File</button>
          <button v-show=false class="bg-gray-700 font-bold text-white cursor-pointer">Copy to Clipboard</button>
        </div>
        -->
      </div>

      <!-- Toy Table -->
      <!--
      <p>Toy Table</p>
      <table class="chariteer-table">
        <thead>
          <tr>
            <th>Full Name</th>
            <th v-if="dataLifetimeVolunteerHoursIsVisible">Hours Volunteered</th>
            <th>Percentage</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Frank DiCola</td>
            <td v-if="dataLifetimeVolunteerHoursIsVisible">37</td>
            <td>99.7%</td>
          </tr>
          <tr>
            <td>Wendy Joyner</td>
            <td v-if="dataLifetimeVolunteerHoursIsVisible">80</td>
            <td>99.3%</td>
          </tr>
          <tr>
            <td>Dunkey Jones</td>
            <td v-if="dataLifetimeVolunteerHoursIsVisible">4</td>
            <td>50.0%</td>
          </tr>
        </tbody>
      </table>
      -->

      <!-- OLD Chariteer Table -->
      <!-- 
      <table class="chariteer-table">
        <thead>
          <tr>
            <th>Last Name</th>
            <th>First Name</th>     
            <th v-if="dataPhoneNumberIsVisible">Phone Number</th>
            <th v-if="dataEmailAddressIsVisible">Email Address</th>
            <th v-if="dataLifetimeNumberOfCompletedQuestsIsVisible">Completed Quests</th>
            <th v-if="dataLifetimeVolunteerHoursIsVisible">Hours Volunteered</th>
            <th v-if="dataGroupRoleNameIsVisible">Group Role</th>
            <th v-if="dataGoalNamesAreVisible">Goals</th>
            <th v-if="dataGoalProgressBarsAreVisible">Progress</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="chariteer in sortByGroupRole" :key="chariteer.playFabID">
            <td class="clickable" @click="viewThisChariteer(chariteer.playFabID)">{{ chariteer.realLastName }}</td>
            <td class="clickable" @click="viewThisChariteer(chariteer.playFabID)">{{ chariteer.realFirstName }}</td>
            <td v-if="dataPhoneNumberIsVisible">{{ chariteer.realPhoneNumber }}</td>
            <td v-if="dataEmailAddressIsVisible">{{ chariteer.realEmailAddress }}</td>
            <th v-if="dataLifetimeNumberOfCompletedQuestsIsVisible && chariteer.completedQuests != undefined">{{ chariteer.completedQuests.length }}</th>
            <td v-if="dataLifetimeVolunteerHoursIsVisible">{{ chariteer.lifetimeHoursVolunteered / 60 }}</td>
            <td v-if="dataGroupRoleNameIsVisible">{{ chariteer.realGroupRoleName }}</td>
            <td v-if="dataGoalNamesAreVisible">Grand Quest</td>
            <td v-if="dataGoalProgressBarsAreVisible">
              {{ chariteer.goalHoursVolunteered / 60 }} <span v-show="chariteer.realGroupRoleName=='Sophomores'">/ 15</span><span v-show="chariteer.realGroupRoleName=='Juniors'">/ 60</span><span v-show="chariteer.realGroupRoleName=='Seniors'">/ 20</span>
              <div class="progress-bar-border">
                <div class="progress-bar-danger" :style="{ 'width': (calculateChariteerProgress(chariteer) * 100) + '%' }" v-bind:class="{ midProgress: calculateChariteerProgress(chariteer) >= 0.5, goodProgress: calculateChariteerProgress(chariteer) >= 0.8 }"></div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      -->

      <!-- This is the Database Version of this Page -->
      <template v-if="currentlySelectedPreset == 'Leaderboard'">
        <h3>
          {{
            "You have " +
              `${databaseChariteers?.getSpecificChariteers.length || 0}` +
              " " +
              `${
                databaseChariteers?.getSpecificChariteers.length == 1
                  ? "volunteer"
                  : "volunteers"
              }`
          }}
        </h3>
        <div class="flex justify-between px-3 py-2">
          <div>
            Volunteer Name
          </div>
          <div>
            Hours Volunteered
          </div>
        </div>
        <div
          v-for="chariteerTimeResult in databaseChariteers?.getSpecificChariteers"
          :key="chariteerTimeResult.chariteer.id"
        >
          <ChariteerCard
            :chariteerData="chariteerTimeResult.chariteer"
            :minutesVolunteered="chariteerTimeResult.combinedMinutes"
            :filterPreset="currentlySelectedPreset"
            :handleOnClick="viewThisChariteer"
          />
        </div>
      </template>
      <template v-else-if="currentlySelectedPreset == 'Training'">
        <div v-show="false">
          <!-- My style explorations-->
          <h3>Introductory Zoom Call</h3>
          <p>
            Maybe we need to have the description here just so people don't
            forget what it was.
          </p>
          <div class="bg-gray-200 my-1 rounded-md shadow-sm">
            <div class="flex justify-between px-5 py-2">
              <span>
                Frank DiCola
              </span>
              <span>
                January 10 2025
              </span>
              <span>
                January 10 2030
              </span>
              <select
                name="change-role-dropdown"
                class="change-role-dropdown"
                v-on:change="
                  changeRole(
                    roleGroup.RoleId,
                    member.Key,
                    $event.target.value,
                    roleGroupIndex,
                    index
                  )
                "
              >
                <option value="admins">Interested</option>
                <option value="members">Member</option>
                <option value="noobs">Noob</option>
              </select>
            </div>
          </div>
          <div class="bg-gray-200 my-1 rounded-md shadow-sm">
            <div class="flex justify-between px-5 py-2">
              <span>
                Frank DiCola
              </span>
              <span>
                January 10 2025
              </span>
              <span>
                January 10 2030
              </span>
            </div>
          </div>
          <h3>Background Check</h3>
          <p>
            Maybe we need to have the description here just so people don't
            forget what it was.
          </p>
          <!-- One table for each Training -->
          <table class="w-full text-left border-collapse">
            <!-- Table Header -->
            <thead>
              <tr class="bg-white">
                <th class="p-2 text-sm font-semibold text-gray-700">
                  First Name
                </th>
                <th class="p-2 text-sm font-semibold text-gray-700">
                  Start Date
                </th>
                <th class="p-2 text-sm font-semibold text-gray-700">
                  Expiration Date
                </th>
                <th class="p-2 text-sm font-semibold text-gray-700">Status</th>
              </tr>
            </thead>

            <!-- Table Body -->
            <tbody>
              <tr v-for="n in 2" :key="n">
                <td class="p-2 text-sm text-gray-600">Frank DiBolus</td>
                <td class="p-2 text-sm text-gray-600">Jan 11 2025</td>
                <td class="p-2 text-sm text-gray-600">Jan 12 2028</td>
                <td class="p-2 text-sm text-gray-600">
                  <select
                    name="change-role-dropdown"
                    v-on:change="
                      changeTrainingStatus(
                        roleGroup.RoleId,
                        member.Key,
                        $event.target.value,
                        roleGroupIndex,
                        index
                      )
                    "
                  >
                    <option value="interested">Interested</option>
                    <option value="provisional">Provisional</option>
                    <option value="complete">Complete</option>
                    <option value="failed">Failed</option>
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Training List Component -->
        <TrainingList
          :selectedManagedProviderId="$props.selectedManagedProviderId"
        />
      </template>

      <!-- First attempt -->
      <!--
      <template v-for="chariteer in $store.state.chariteerDictionary" :key="chariteer.playFabID">
          <div class="chariteerDisplayRowButton">
            <div class="chariteerDisplayRow">
              <p class="chariteerDataCell">{{ chariteer.realLastName + "," }}</p>
              <p class="chariteerDataCell">{{ chariteer.realFirstName }}</p>
              <p class="chariteerDataCell">{{ chariteer.realPhoneNumber }}</p>
              <p class="chariteerDataCell">{{ chariteer.realEmailAddress }}</p>
            </div>
          </div>
      </template>
      -->

      <!-- LOADOUTS -->
      <!-- I can imagine a variety of cool preset Loadouts for this menu. There can always be advanced configuration, but it makes sense to bundle certain columns together: -->
      <!--    VALUE         (Hours Volunteered, Money Donated, Supply Money Spent, Reputation)    -->
      <!--    TRAINING      (Orientations In Progress)                                            -->
      <!--    SKILLS        (Organize By Self Reported Skills and Levels)                         -->
      <!--    VOLUNTEER     (Full Name, Total Hours Volunteered, Batting Average, Player Level)   -->
      <!--    FUNDING       (Full Name, Cash Donations, Supply Donations)                         -->
      <!--    CONTACT       (Phone Number, Email, Guardian Email)                                 -->

      <!--    LEGAL         (Name, Date of Signing Omnibus Waiver, Waiver Version)                -->
      <!--    TIME          (Name, Active Hours [data analysis], Active Hours [what they claim])  -->
      <!--    EQUIPMENT     (Name, All Items like cars, power tools)                              -->
      <!--    EMERGENCY     (Name, Phone, Email, Emergency Contact Name, Emergency Phone)         -->
      <!--    DISCIPLINED   (Banned, Blocked)                                                     -->
      <!--    ALL           (Get ready to scroll horizontally...)                                 -->

      <!--    Schools...    -->
      <!--    GOALS         (Name, Goal Name(s), Goal Progress Bar(s) [Current Amount / Goal Amount])   -->

      <!-- ROWS -->
      <!-- Just like the different way of organizing columns above, rows should be able to be grouped according to things that make sense. -->
      <!--    LOCATION      Rows are organized by Location, which either means the location the volunteer loves the most or just one they have signed up for. Tough one... -->
      <!--    GROUP ROLE    Rows are organized by Group Role in whatever order PlayFab wants. Row groups can be collapsed and reordered, which is saved as a preference. -->
    </div>

    <!-- Chariteer (Modal) -->
    <div class="chariteer-modal" v-if="false">
      <!-- chariteerModalIsVisible -->
      <!-- Modal content -->
      <div class="chariteer-modal-content">
        <!-- Top Buttons -->
        <div class="chariteer-modal-top-button-row">
          <button class="modal-close" v-on:click="closeChariteerModal">
            ×
          </button>
        </div>

        <!-- Basic Info -->
        <div class="chariteer-modal-basic-info">
          <h1>
            {{
              $store.state.currentlyViewedChariteer.nameFirst +
                " " +
                $store.state.currentlyViewedChariteer.nameLast
            }}
          </h1>
          <h2 class="m-0 text-gray-600 font-normal">
            {{ $store.state.currentlyViewedChariteer.username }}
          </h2>
          <!-- Deprecated because this was for the school view. -->
          <!-- <p><b>Rank:</b> {{ $store.state.currentlyViewedChariteer.realGroupRoleName }}</p> -->
          <!-- <p><b>Age:</b> ???</p> -->
          <p>
            <b>Email Address:</b>
            {{ $store.state.currentlyViewedChariteer.emailAddress }}
          </p>
          <p>
            <b>Mobile Number:</b>
            {{ $store.state.currentlyViewedChariteer.phoneNumber }}
          </p>
        </div>

        <!-- Stats: Favorite Charities -->
        <!-- each charity has a circle?-->
        <!-- Stat: Lifetime Quests completed -->

        <!-- All of this Chariteer's Completed Quests -->
        <div class="chariteer-modal-quest-history">
          <h2 class="chariteer-modal-section-heading">Quest History</h2>

          <div
            class="chariteer-modal-no-completed-quests"
            v-if="
              $store.state.currentlyViewedChariteer.completedQuests ==
                undefined ||
                $store.state.currentlyViewedChariteer.completedQuests.length <=
                  0
            "
          >
            <p class="empty-disclaimer-header">
              This Chariteer has not completed any Quests yet.
            </p>
          </div>

          <div class="chariteer-modal-completed-quests" v-else>
            <ul
              v-for="completedQuest in $store.state.currentlyViewedChariteer
                .completedQuests"
              :key="completedQuest.providerID + completedQuest.eventID"
            >
              <li class="completedQuest">
                <!-- Left Edge: The Emblem -->
                <div>
                  <img
                    class="quest-category-icon"
                    alt="Quest category icon"
                    src="../assets/CQ-Medallion.png"
                  />
                </div>

                <!-- Left Side: Text Details -->
                <!-- Who, what, why, where, whe'em, and whence -->
                <div class="questDetails">
                  <h3>{{ completedQuest.eventTitle }}</h3>
                  <h4>{{ completedQuest.providerName }}</h4>
                  <p>On-site volunteer shift on {{ completedQuest.date }}</p>
                  <p>Attendance recorded by {{ completedQuest.giverName }}</p>
                </div>

                <!-- Right Side: Results -->
                <!-- Was this vol here? dismissed? failed? -->
                <div
                  class="questResultPresent"
                  v-if="determineQuestResult(completedQuest) == 'Present'"
                >
                  <img
                    class="quest-result-icon"
                    alt="Quest result icon"
                    src="../assets/CQ-Butticon_Present.png"
                  />
                  <p class="quest-result-text">
                    {{ displayHoursAwardedText(completedQuest) }}
                  </p>
                </div>

                <div
                  class="questResultDismissal"
                  v-else-if="
                    determineQuestResult(completedQuest) == 'Dismissed'
                  "
                >
                  <img
                    class="quest-result-icon"
                    alt="Quest result icon"
                    src="../assets/Placeholder-Square.png"
                  />
                  <p><b>Dismissed</b></p>
                </div>

                <div
                  class="questResultAbsent"
                  v-else-if="determineQuestResult(completedQuest) == 'Absent'"
                >
                  <img
                    class="quest-result-icon"
                    alt="Quest result icon"
                    src="../assets/CQ-Butticon_Absent.png"
                  />
                  <p><b>Absence Recorded</b></p>
                </div>

                <div
                  class="questResultDismissal"
                  v-else-if="determineQuestResult(completedQuest) == 'Unknown'"
                >
                  <img
                    class="quest-result-icon"
                    alt="Quest result icon"
                    src="../assets/CQ-Butticon_Unknown.png"
                  />
                  <p><b>Unknown</b></p>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <!-- Goals and Progress -->
        <!-- Again, this was a school thing
        <div class="chariteer-modal-goal-progress">
          <h2 class="chariteer-modal-section-heading">Goal Progress</h2>
          <div class="chariteer-modal-no-goals">
            <p class="empty-disclaimer-header">This Chariteer has not claimed any Goals yet.</p>
          </div>
        </div>
        -->

        <!-- Upcoming Quests -->
        <!-- This may still be a good idea, actually! -->
      </div>
    </div>

    <!-- Chariteer Preview -->
    <ChariteerPreview
      v-if="chariteerModalIsVisible"
      class="ml-5"
      :close="handleCloseChariteerPreviewModal"
      :chariteerData="this.$store.state.currentlyViewedChariteer"
    />
  </div>
</template>

<script>
import ChariteerPreview from "@/components/modals/ChariteerPreview.vue";
import ChariteerCard from "@/components/cards/ChariteerCard.vue";
import TrainingList from "@/components/TrainingList.vue";
import { useQuery } from "@vue/apollo-composable";
import { useRoute } from "vue-router";
import gql from "graphql-tag";
import { DeprecationTypes, toRefs } from "vue";

export default {
  name: "ChariteerList",
  data() {
    return {
      somekindafilterinfo: "",
      gridColumns: ["name", "power"],
      columnDisplayName: "None",
      columnDisplayText: "Choose a column display below to filter the data.",
      dataPhoneNumberIsVisible: false,
      dataEmailAddressIsVisible: false,
      dataLifetimeNumberOfCompletedQuestsIsVisible: false,
      dataLifetimeVolunteerHoursIsVisible: false,
      dataGroupRoleNameIsVisible: false,
      dataGoalNamesAreVisible: false,
      dataGoalProgressBarsAreVisible: false,
      chariteerModalIsVisible: false,
      currentlySelectedPreset: "Leaderboard"
    };
  },
  components: {
    ChariteerPreview,
    ChariteerCard,
    TrainingList
  },
  props: [
    "managedProviderMemberships",
    "selectedManagedProviderId",
    "topVolRangeStart",
    "topVolRangeEnd"
  ],
  setup(props) {
    const route = useRoute();
    const {
      topVolRangeStart: topVolRangeStartDate,
      topVolRangeEnd: topVolRangeEndDate
    } = toRefs(props);

    console.log(
      "Selected Managed Provider ID is " + props.selectedManagedProviderId
    );
    // console.log(this.$store.state.managedProviderGroupMemberships[0].ManagedProviderId);

    console.log("Our props are " + props);
    console.log(props.topVolRangeStart);
    console.log(props.topVolRangeEnd);
    console.log("Our refs are... ");
    console.log(topVolRangeStartDate);
    console.log(topVolRangeEndDate);

    const { result, loading, error, refetch, onResult } = useQuery(
      gql`
        query GetSpecificChariteers($GCI: GetChariteersInput!) {
          getSpecificChariteers(getChariteersInput: $GCI) {
            chariteer {
              id
              username
              nameFirst
              nameLast
            }
            combinedMinutes
          }
        }
      `,
      // This is variables - and it has to look like this
      {
        GCI: {
          specificProvider: props.selectedManagedProviderId,
          dateRangeBeginning: topVolRangeStartDate,
          dateRangeEnding: topVolRangeEndDate
        }
      }
      // headers maybe?
      // AUTHORIZATION
    );

    console.log("Refetching Chariteers!");
    console.log(result);
    //  console.log("Can we see the Chariteers we refetched?");
    console.log(result.chariteers);
    console.log(error);

    return {
      databaseChariteers: result || null,
      loading,
      error,
      refetch,
      onResult,
      ourManagedProviderID: props.selectedManagedProviderId,
      topVolRangeStartDate,
      topVolRangeEndDate
    };
  },
  watch: {
    async selectedManagedProviderId(newValue, oldValue) {
      if (newValue !== oldValue) {
        await this.refetch({
          GCI: {
            specificProvider: newValue,
            dateRangeBeginning: this.topVolRangeStartDate,
            dateRangeEnding: this.topVolRangeEndDate
          }
        });
      }
    }
  },
  methods: {
    methodName(parameter) {
      console.log("Sweet parameter: " + parameter);
      console.log(
        this.$store.state.chariteerDictionary[parameter].fullName +
          " has an ID of " +
          this.$store.state.chariteerDictionary[parameter].playFabID
      );
    },
    filterByPreset(preset) {
      this.hideAllColumns();
      this.columnDisplayName = "None";

      if (preset == "Contact Info") {
        this.columnDisplayName = "Contact Info";
        this.columnDisplayText =
          "The Contact View shows you basic contact information for your volunteers.";
        this.showPhoneNumber();
        this.showEmailAddress();
      } else if (preset == "Activity") {
        this.columnDisplayName = "Activity";
        this.columnDisplayText =
          "The Activity View shows you information about how many Quests each volunteer has completed.";
        this.showLifetimeNumberOfCompletedQuests();
        this.showLifetimeVolunteerHours();
      } else if (preset == "Goals") {
        this.columnDisplayName = "Goals";
        this.columnDisplayText =
          "The Goals View allows you to see any Goals you have given your volunteers, as well as their current progress.";
        this.showGroupRoleName();
        this.showGoalNames();
        this.showGoalProgressBars();
      } else {
        console.log("Unrecognized loadout filter.");
        this.columnDisplayName = "None";
      }
    },
    filterByUserSelectedValues() {
      console.log("Refetching! " + this.props.topVolRangeStart);
      this.refetch();
    },
    hideAllColumns() {
      // Set them all to false!
      // CONTACT INFO
      this.dataPhoneNumberIsVisible = false;
      this.dataEmailAddressIsVisible = false;

      // COMPLETED QUESTS
      this.dataLifetimeNumberOfCompletedQuestsIsVisible = false;
      this.dataLifetimeVolunteerHoursIsVisible = false;

      // GROUP
      this.dataGroupRoleNameIsVisible = false;

      // GRAND QUESTS
      this.dataGoalNamesAreVisible = false;
      this.dataGoalProgressBarsAreVisible = false;
    },
    showPhoneNumber() {
      this.dataPhoneNumberIsVisible = true;
    },
    showEmailAddress() {
      this.dataEmailAddressIsVisible = true;
    },
    showLifetimeNumberOfCompletedQuests() {
      this.dataLifetimeNumberOfCompletedQuestsIsVisible = true;
    },
    showLifetimeVolunteerHours() {
      this.dataLifetimeVolunteerHoursIsVisible = true;
    },
    showGroupRoleName() {
      this.dataGroupRoleNameIsVisible = true;
    },
    showGoalNames() {
      this.dataGoalNamesAreVisible = true;
    },
    showGoalProgressBars() {
      this.dataGoalProgressBarsAreVisible = true;
    },
    viewThisChariteer(chariteerToView) {
      console.log("Viewing Chariteer with ID " + chariteerToView.id);

      this.$store.state.currentlyViewedChariteer = chariteerToView;
      this.chariteerModalIsVisible = true;

      console.log(
        "Show pop-up for " +
          this.$store.state.currentlyViewedChariteer.nameFirst +
          " " +
          this.$store.state.currentlyViewedChariteer.nameLast
      );
    },
    handleCloseChariteerPreviewModal() {
      this.$store.state.currentlyViewedChariteer = {};
      this.chariteerModalIsVisible = false;
    },
    calculateChariteerProgress(chariteer) {
      var hours = chariteer.goalHoursVolunteered / 60;

      if (chariteer.realGroupRoleName == "Sophomores") {
        // console.log( "This Sophomore has " + hours + " of 15 done, which is " + (hours / 15) )
        return hours / 15;
      } else if (chariteer.realGroupRoleName == "Juniors") {
        // console.log( "This Junior has " + hours + " of 60 done, which is " + (hours / 60) )
        return hours / 60;
      } else if (chariteer.realGroupRoleName == "Seniors") {
        // console.log( "This Senior has " + hours + " of 20 done, which is " + (hours / 20) )
        return hours / 20;
      } else return 0;
    },
    determineQuestResult(completedQuest) {
      if (completedQuest.timeCredit > 0 && completedQuest.repCredit > 0)
        return "Present";
      else if (completedQuest.timeCredit == 0 && completedQuest.repCredit == 0)
        return "Dismissed";
      else if (completedQuest.timeCredit == 0 && completedQuest.repCredit < 0)
        return "Absent";
      else return "Unknown";
    },
    displayHoursAwardedText(completedQuest) {
      var hours = completedQuest.timeCredit / 60;
      var minutes = completedQuest.timeCredit % 60;
      hours = Math.trunc(hours);

      console.log(completedQuest.eventTitle + ": " + hours + ", " + minutes);

      if (hours < 1) return minutes + " minutes";
      else if (hours == 1 && minutes == 0) return hours + " hour";
      else if (hours == 1 && minutes > 0)
        return hours + " hour, " + minutes + " minutes";
      else if (hours > 1 && minutes == 0) return hours + " hours";
      else return hours + " hours, " + minutes + " minutes";
    },
    downloadChariteerDataAsCSVFile() {
      console.log("Downloading CSV data!");
      // Do it
      // https://stackoverflow.com/questions/58292771/downloading-a-csv-of-file-using-vue-and-js

      // It needs to be in an array
      const array = Object.values(this.$store.state.chariteerDictionary);

      let csv = "First Name,Last Name,Phone Number,Email Address\n";
      array.forEach(row => {
        csv += row.realFirstName + ",";
        csv += row.realLastName + ",";
        csv += row.realPhoneNumber + ",";
        csv += row.realEmailAddress + ",";
        csv += "\n";
      });

      //csv += row.join(',');

      const anchor = document.createElement("a");
      anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
      anchor.target = "_blank";
      anchor.download = "Chariteers.csv";
      anchor.click();
    },
    copyChariteerDataToClipboard() {
      // navigator.clipboard.writeText('text here');
    }
  },
  created() {
    console.log("Getting more info on Chariteers...");

    // Dispatch an action that takes our Service Records and fills it with each Chariteer's information.
    // this.$store.dispatch('fillChariteerDictionary')
  },
  computed: {
    sortByGroupRole() {
      // The getter code is going to handle sorting for us
      console.log(this.$store.getters.sortDictionary);
      return this.$store.getters.sortDictionary;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.chariteersBox {
  background-color: #ffa94e;
  width: 800px;
  padding: 0px;
  margin: 0px auto; /* Set the box to be centered */
}

h1 {
  margin: 0px;
}

h4 {
  margin: 0px;
}

h3 {
  margin: 0px;
  margin-top: 25px;
}

p {
  text-align: justify;
  margin: 0px;
}

ul {
  margin: 0px;
  padding: 0px;
}

.clickable {
  cursor: pointer;
}

/* Trying tables, don't be mad */

.chariteer-table {
  border-collapse: collapse;
  margin-top: 0px;
  margin-bottom: 50px;
  font-size: 0.9em;
  overflow: hidden;
}

.chariteer-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* Buttons for changing the columns */
.preset-loadouts-flexbox {
  display: flex;
}
.column-filter-button {
  border: none;
  padding: 0px;
  width: 100px;
  height: 100px;
  margin-left: 10px;
  margin-right: 10px;
  font-family: Palanquin, Montserrat, Helvetica, Arial, sans-serif;
  cursor: pointer;
}
.column-filter-button p {
  text-align: center;
}
.columnSelected {
  background-color: #ffa94e38;
}

.chariteers {
  display: grid;
  /*
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  */
  background-color: #ffffff;
  text-align: left;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.25);
}

.chariteerDisplayRowButton {
  font-size: 75%;
  font-weight: 400;
  list-style: none;
  background-color: rgb(240, 240, 240);
  border: none;
  border-radius: 5px;
  margin-top: 1px;
  margin-bottom: 1px;
  padding: 0px;
}

.chariteerDisplayRow {
  /*
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: left;
  */
  background-color: rgba(241, 35, 35, 0);
  text-align: left;
  border-radius: 5px;
  padding: 10px;

  font-size: 120%;
  color: rgb(50, 50, 50);
  font-family: Palanquin, Montserrat, Helvetica, Arial, sans-serif;
}

/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* Chariteer Modal */

.chariteer-modal {
  /* display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.6); /* Black w/ opacity */
}
.chariteer-modal-content {
  background-color: #ffffff;
  border-radius: 0.5vw;
  margin: auto;
  margin-top: 15vh;
  padding: 0px;
  width: 75vw;
}

.chariteer-modal-top-button-row {
  display: flex;
  flex-direction: row-reverse;
  height: 5vh;
}

.modal-close {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  text-align: center;
  font-size: 300%;
  width: 5vh;
}

.chariteer-modal-section-heading {
  text-align: left;
  margin: 0px;
}

.chariteer-modal-basic-info {
  color: rgba(50, 50, 50, 1);
  background-color: rgba(128, 0, 128, 0);
  text-align: left;

  margin-left: 2vw;
  margin-right: 2vw;
}

.empty-disclaimer-header {
  color: grey;
  font-weight: 700;
  padding-top: 8vh;
  text-align: center;
}

/* = = = = = = = = = = = = = = = = */
/* Chariteer Modal: Quest History */

.chariteer-modal-quest-history {
  background-color: rgba(255, 166, 0, 0);
  margin-top: 1vh;
  margin-left: 2vw;
  margin-right: 2vw;
}

.chariteer-modal-completed-quests {
  display: flex;
  flex-direction: column-reverse;
  background-color: rgb(240, 240, 240);
  border-radius: 0.5vw;
  width: 100%;
  height: 35vh;
  overflow-y: auto;
}

.chariteer-modal-no-completed-quests {
  background-color: rgb(240, 240, 240);
  border-radius: 0.5vw;
  width: 100%;
  height: 20vh;
}

.completedQuest {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  gap: 2vw;
  background-color: rgb(252, 252, 252);
  border-radius: 1vw;
  margin-top: 1vh;
  margin-bottom: 1vh;
  margin-left: 1vh;
  margin-right: 1vh;
  padding-left: 1vh;
  height: 20vh;
}

.quest-category-icon {
  background-color: rgba(128, 0, 128, 0);
  height: 15vh;
}

.questDetails {
  flex-grow: 2;
  background-color: rgba(255, 255, 0, 0);
  text-align: left;
  height: 20vh;
}

.quest-result-icon {
  height: 10vh;
}

.quest-result-text {
  color: white;
  font-weight: 900;
  text-align: center;
}

.questResultPresent {
  color: white;
  background-color: rgb(69, 197, 118);
  border-radius: 0 1vw 1vw 0;
  text-align: center;
  height: 20vh;
}

.questResultDismissal {
  color: white;
  background-color: rgb(128, 128, 128);
  border-radius: 0 1vw 1vw 0;
  text-align: center;
  height: 20vh;
}

.questResultAbsent {
  color: white;
  background-color: rgb(204, 88, 88);
  border-radius: 0 1vw 1vw 0;
  text-align: center;
  height: 20vh;
}

/* = = = = = = = = = = = = = = = = */
/* Chariteer Modal: Goal Progress */

.chariteer-modal-goal-progress {
  background-color: rgba(255, 166, 0, 0);
  margin-top: 1vh;
  margin-left: 2vw;
  margin-right: 2vw;
}

.chariteer-modal-current-goals {
  display: flex;
  flex-direction: column-reverse;
  background-color: rgb(240, 240, 240);
  border-radius: 0.5vw;
  width: 100%;
  height: 35vh;
  overflow-y: auto;
}

.chariteer-modal-no-goals {
  background-color: rgb(240, 240, 240);
  border-radius: 0.5vw;
  width: 100%;
  height: 20vh;
}

/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = */
/* Progress Bars */

.progress-bar-border {
  border: 1px solid #ccc;
}

.progress-bar-danger {
  color: #000;
  background-color: rgb(170, 38, 38);
  height: 1vh;
}

.midProgress {
  color: #000;
  background-color: rgb(207, 152, 70);
  height: 1vh;
}

.goodProgress {
  color: #000;
  background-color: rgb(118, 202, 97);
  height: 1vh;
}

@media only screen and (max-width: 600px) {
  .chariteersBox {
    width: 100%;
  }
}
</style>
